





























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ name: 'StatusManualVerification' })
export default class StatusManualVerification extends Vue {
  accept() {
    this.$emit('accept');
  }
}

