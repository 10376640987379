/* eslint-disable class-methods-use-this */

import { v4 } from 'uuid';
import { BLUE } from '@/constants/colors.json';
import TYPES from '@/types';

// Application
import GetOnBoardingIdentityVerificationServiceQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-on-boarding-identity-verification-service-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { EventBus } from '@/modules/shared/domain/bus/event-bus';

export default class IdentityVerificationViewModel {
  @Inject(TYPES.GET_ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_QUERY)
  readonly getIdentityVerificationServiceQuery!: GetOnBoardingIdentityVerificationServiceQuery;

  @Inject(TYPES.EVENT_BUS)
  private readonly event_bus!: EventBus;

  readonly mati_background_color = BLUE;

  private readonly view: any;

  current_flow_uuid = '';

  get with_dummies() {
    return process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  }

  get user_id() {
    return sessionStorage.getItem('user_id');
  }

  get identity_verification_service() {
    return this.getIdentityVerificationServiceQuery.execute();
  }

  public constructor(view: any) {
    this.current_flow_uuid = v4();
    this.setStorageCurrentFlowId();
    this.view = view;
  }

  setStorageCurrentFlowId = () => {
    sessionStorage.setItem('mati_flow_id', this.current_flow_uuid);
  }

  setCurrentFlowId = () => {
    this.current_flow_uuid = v4();
    this.setStorageCurrentFlowId();
  }

  exitedSdk = () => {
    this.current_flow_uuid = v4();
    this.setStorageCurrentFlowId();
    this.view.$emit('exitedSdk');
  }
}
