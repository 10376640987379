import Vue from 'vue';
import i18n from '@/vue-app/plugins/i18n';

import TYPES from '@/types';

// Application
import GetMatiVerificationQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-mati-verification-query';
import RequestManualReviewCommand from '@/modules/on-boarding/identity-verification/application/commands/request-manual-review-command';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { EventBus } from '@/modules/shared/domain/bus/event-bus';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export class ProcessVerificationErrorViewModel {
  @Inject(TYPES.EVENT_BUS)
  private readonly event_bus!: EventBus;

  @Inject(TYPES.GET_ON_BOARDING_MATI_VERIFICATION_QUERY)
  private readonly get_mati_verification_query!: GetMatiVerificationQuery;

  @Inject(TYPES.REQUEST_MANUAL_REVIEW_COMMAND)
  private readonly request_manual_review_command!: RequestManualReviewCommand;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  readonly i18n_namespace = 'error_process_verification';

  is_loading = false;

  can_retry = false;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  tryAgain = (requested_manual_review = false) => {
    this.event_bus.publish({
      name: 'identity_verification',
      body: {
        status: 'exited',
      },
    });
    this.view.$emit('prevStep', requested_manual_review);
  }

  requestManualReview = async () => {
    try {
      this.is_loading = true;
      await this.request_manual_review_command.execute();
      this.tryAgain(true);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_request_manual_review'));
    } finally {
      this.is_loading = false;
    }
  }

  loadMatiVerificationStatus = async () => {
    try {
      const mati_verification = await this.get_mati_verification_query.execute();
      this.can_retry = mati_verification.attempt_counter < 2;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_mati_verification'));
    }
  }

  initialize = async () => {
    await this.loadMatiVerificationStatus();
  }
}
