


















































































import { Vue, Component } from 'vue-property-decorator';
import VerifyingViewModel from '@/vue-app/view-models/components/on-boarding/verify-identity/verifying-view-model';

@Component({
  name: 'OnBoardingVerifyIdentityVerifying',
  components: {
    ContractTimeout: () => import('@/vue-app/components/contracts/timeout/ContractTimeout.vue'),
  },
})
export default class OnBoardingVerifyIdentityVerifying extends Vue {
  verifying_view_model = Vue.observable(new VerifyingViewModel(this));

  created() {
    this.verifying_view_model.initialize();
  }
}

