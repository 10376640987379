




























































import { Vue, Component, Prop } from 'vue-property-decorator';

import { ProcessVerificationErrorViewModel } from '@/vue-app/view-models/components/errors/process-verification-error-view-model';

@Component({ name: 'ProcessVerificationError' })
export default class ProcessVerificationError extends Vue {
  verification_error_view_model = Vue.observable(new ProcessVerificationErrorViewModel(this));

  async mounted() {
    await this.verification_error_view_model.initialize();
  }
}

