import Vue from 'vue';
import i18n from '@/vue-app/plugins/i18n';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { EventBus } from '@/modules/shared/domain/bus/event-bus';

import TYPES from '@/types';

export default class ErrorMatiViewModel {
  @Inject(TYPES.EVENT_BUS)
  private readonly event_bus!: EventBus;

  private readonly i18n_namespace = 'error_verification_mati';

  private readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  accept = () => {
    this.event_bus.publish({
      name: 'identity_verification',
      body: {
        status: 'exited',
      },
    });
    this.view.$emit('prevStep');
  }
}
