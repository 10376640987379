



































import { Vue, Component } from 'vue-property-decorator';
import ErrorMatiViewModel from '@/vue-app/view-models/components/errors/error-mati-view-model';

@Component({ name: 'ErrorMati' })
export default class ErrorMati extends Vue {
  error_mati_view_model!: ErrorMatiViewModel;

  created() {
    this.error_mati_view_model = Vue.observable(new ErrorMatiViewModel(this));
  }
}

