import Vue from 'vue';

import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import IdentityVerificationStatusSubscription
  from '@/modules/on-boarding/identity-verification/application/subscriptions/identity-verification-status-subscription';
import SearchOnBoardingStepsQuery
  from '@/modules/on-boarding/steps/application/queries/search-on-boarding-steps-query';
import UpdateOnBoardingStepCommand
  from '@/modules/on-boarding/steps/application/commands/update-on-boarding-step-command';
import GetMatiVerificationQuery from '@/modules/on-boarding/identity-verification/application/queries/get-mati-verification-query';
import { ResetIdentityVerificationStatus } from '@/modules/on-boarding/identity-verification/application/services';
import SignOutService from '@/modules/authentication/application/services/sign-out-service';

// Domain
import {
  MatiVerificationEntity,
} from '@/modules/on-boarding/identity-verification/domain/entities/mati-verification-entity';
import { StepEntity } from '@/modules/on-boarding/steps/domain/entities/step-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

export default class VerifyIdentityViewModel {
  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION)
  readonly identityVerificationStatusSubscription!: IdentityVerificationStatusSubscription;

  @Inject(TYPES.SEARCH_ON_BOARDING_STEPS_QUERY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.UPDATE_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.GET_ON_BOARDING_MATI_VERIFICATION_QUERY)
  private readonly get_mati_verification_query!: GetMatiVerificationQuery;

  @Inject(TYPES.ON_BOARDING_RESET_IDENTITY_VERIFICATION_STATUS)
  readonly reset_identity_verification_status!: ResetIdentityVerificationStatus;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly view: Vue;

  readonly i18n_namespace = 'sign-up.verify-identity';

  current_step: StepEntity = {
    id: '',
    current_step: '',
    payload: {},
  };

  is_loading = false;

  show_status_manual_verification = false;

  mati_verification: MatiVerificationEntity = {
    id: '',
    customer_id: '',
    status: '',
    attempt_counter: 0,
    created_at: '',
    updated_at: '',
  };

  unexpected_error = false;

  show_mati_verification_error = false;

  in_progress = false;

  source = 'onboarding';

  constructor(view: Vue) {
    this.view = view;
  }

  get allow_verified() {
    return !this.mati_verification.status && !this.in_progress;
  }

  translate = (identifier: string) => (
    i18n.t(`${this.i18n_namespace}.${identifier}`) as string
  )

  verifyMatiVerificationStatus = async (mati_verification: MatiVerificationEntity) => {
    this.show_status_manual_verification = (
      mati_verification.status === 'manual_review_requested' || mati_verification.status === 'manual_review_required'
      || this.current_step.payload.status === 'manual_review_requested'
    );
    this.show_mati_verification_error = mati_verification.status === 'has_error' || mati_verification.status === 'manually_rejected';
    this.in_progress = mati_verification.status === 'verified' || mati_verification.status === 'manually_verified';
  };

  loadMatiVerificationStatus = async () => {
    try {
      this.mati_verification = await this.get_mati_verification_query.execute(true);
      await this.verifyMatiVerificationStatus(this.mati_verification);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_mati_verification'));
    }
  }

  acceptManualReviewRequest = async () => {
    await new SignOutService().logout('/login');
  }

  acceptErrorMati = (requested_manual_review = false) => {
    this.in_progress = false;
    this.unexpected_error = false;
    this.reset_identity_verification_status.execute();
    this.mati_verification.status = '';
    this.show_status_manual_verification = requested_manual_review;
  }

  acceptVerificationError = (requested_manual_review = false) => {
    this.reset_identity_verification_status.execute();
    this.in_progress = false;
    this.show_mati_verification_error = false;
    this.show_status_manual_verification = requested_manual_review;
    if (!requested_manual_review) {
      this.mati_verification.status = '';
    }
  }

  verificationExpired = () => {
    this.mati_verification.status = 'verification_expired';
    this.reset_identity_verification_status.execute();
    this.in_progress = false;
  }

  unexpectedError = () => {
    this.mati_verification.status = 'unexpected_error';
    this.in_progress = false;
    this.unexpected_error = true;
  }

  hasError = () => {
    this.mati_verification.status = 'has_error';
    this.in_progress = false;
    this.show_mati_verification_error = true;
  }

  manualReviewRequired = () => {
    this.mati_verification.status = 'manual_review_required';
    this.in_progress = false;
    this.show_status_manual_verification = true;
  }

  exitedSdk = () => {
    this.in_progress = false;
    this.reset_identity_verification_status.execute();
  }

  loadCurrentStep = async () => {
    try {
      const steps = await this.searchOnBoardingStepsQuery.execute({ sort_field: 'created_at' });
      this.current_step = steps.find((step) => step.current_step === 'identity_verification')!;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_current_step'));
    }
  }

  connectToSocket = async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.identityVerificationStatusSubscription.execute(sessionStorage.getItem('user_id')!);
  }

  nextStep = async () => {
    await this.connectToSocket();
    this.reset_identity_verification_status.execute();
    this.in_progress = true;
  }

  continue = () => {
    this.view.$emit('nextStep');
  }

  changeTitle = async () => {
    await this.view.$store.dispatch('layout/setLayoutHeader', {
      title: this.translate('check'),
      right_action: {
        step: 3,
      },
    });
  }

  initialize = async () => {
    this.is_loading = true;
    await this.changeTitle();
    await this.loadCurrentStep();
    await this.loadMatiVerificationStatus();
    await this.connectToSocket();
    this.is_loading = false;
  }
}
