





























































































import { Component, Vue } from 'vue-property-decorator';
import IdentityVerification from '@/vue-app/components/IdentityVerification.vue';
import StatusManualVerification from '@/vue-app/components/errors/StatusManualVerification.vue';
import ProcessVerificationError from '../../errors/ProcessVerificationError.vue';
import ErrorMati from '@/vue-app/components/errors/ErrorMati.vue';
import Verifying from '@/vue-app/components/on-boarding/verify-identity/Verifying.vue';
import VerifyIdentityViewModel from '@/vue-app/view-models/components/on-boarding/verify-identity/verify-identity-view-model';

@Component({
  name: 'OnBoardingVerifyIdentity',
  components: {
    StatusManualVerification,
    IdentityVerification,
    ProcessVerificationError,
    ErrorMati,
    Verifying,
  },
})
export default class OnBoardingVerifyIdentity extends Vue {
  verify_identity_view_model = Vue.observable(new VerifyIdentityViewModel(this));

  $refs!: {
    identity_verification: IdentityVerification;
  }

  async created() {
    await this.verify_identity_view_model.initialize();
  }

  acceptErrorMati = () => {
    this.$refs.identity_verification.identity_verification_view_model.setCurrentFlowId();
    this.verify_identity_view_model.acceptErrorMati();
  }

  acceptVerificationError = (requested_manual_review = false) => {
    this.$refs.identity_verification.identity_verification_view_model.setCurrentFlowId();
    this.verify_identity_view_model.acceptVerificationError(requested_manual_review);
  }
}

